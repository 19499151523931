@use "./َArticleContent";
@use "./ArticleRate";
@use "./AsideArticle";

.article {
  display: flex;
  flex-direction: row-reverse;
  max-width: 1040px;
  margin: 0 auto;
  padding-top: 44px;
  padding-bottom: 140px;
  width: 100%;
  a {
    all: revert !important;
  }

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    max-width: 90%;
    padding-top: 24px;
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 860px) {
    max-width: 95%;
  }

  @media only screen and (max-width: 340px) {
    padding-bottom: 40px;
  }
}

.article-aside {
  width: 20%;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
}

.article-blog {
  width: 80%;
  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
}
