.all-categories {
  margin-top: 50px;
  margin-bottom: 150px;

  @media only screen and (max-width: 480px) {
    margin-top: 24px;
    margin-bottom: 80px;
  }
}

.all-categories-title {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.all-categories-articles {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-rows: 6;

  a {
    cursor: pointer;
  }

  @media only screen and (max-width: 780px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 20px;
  }

  @media only screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 20px;
  }
}
