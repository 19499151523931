@use "./CategoryItem";
@use "./AsideCategory";

.category {
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 44px;
  padding-bottom: 140px;

  @media only screen and (max-width: 860px) {
    padding-top: 24px;
    padding-bottom: 80px;
  }
}

.category-aside {
  @media only screen and (max-width: 860px) {
    display: none;
  }
}

.category-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  a {
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
